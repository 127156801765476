import styled from 'styled-components'

export const FeaturedSliderWrapper = styled.div`
    margin: 0 auto;
    padding: 20px 0;
    width: 90%;
    overflow: hidden;

    @media(min-width: 700px) {
        padding: 0;
    }

    .slick-track {
        display: flex;
    }
`;

export const Slide = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 130px;
    padding: 0 24px;

    img {
        height: 100%;
        object-fit: contain;
    }
`;