import React from 'react'
import {
    Image
} from "./ImageGridStyles"

const ImageGrid = ({layout, imagePath, keyName}) => {
    return (
        <Image key={keyName} className={layout}>
            <img src={imagePath} alt="" />
        </Image>
    )
}

export default ImageGrid

