import styled from 'styled-components'

export const ImageGridWrapper = styled.div`
    display: grid;
    grid-gap: 12px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 2;
    margin: 24px 12px;

    @media(min-width: 900px) {
        grid-gap: 20px;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 1;
        margin: 40px;
    }
`;

export const Image = styled.div`
    width: 100%;
    height: 100%;
    background: #ddd;
    overflow: hidden;

    @media(min-width: 900px) {
       grid-row-start: 1;
       grid-row-end: 2;
    }

    &:last-child {
        grid-row: 2;
        grid-column: 1 / 3;
        
        @media(min-width: 900px) {
            grid-column: 3 / 5;
            grid-row-start: 1;
            grid-row-end: 2;
        }
    }

    img {
        height: 100%;
        object-fit: cover;
    }
`;