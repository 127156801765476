import React from 'react'
import {
    ImageTextElm,
    ImageTextWrapper,
    ImageTextCopy,
    Copy,
    LgNum,
    IntroText,
    CopyRow,
    Image,
    Border
} from './ImageTextBlockStyles'
import { StaticImage } from 'gatsby-plugin-image'

const ImageText = ({backgroundColor, copy}) => {
    return (
        <ImageTextElm
            style={{
                background: `${backgroundColor}`
            }}
        >
            <ImageTextWrapper>
                <ImageTextCopy>
                    <Copy>
                        <IntroText>
                            <p>
                                <strong>
                                    We offer hand-selected tree stock that has been carefully chosen by experienced professionals. We have 3 principles we follow when selecting our olives trees:
                                </strong>
                            </p>
                        </IntroText>
                        <CopyRow>
                            <p style={{paddingLeft: "40px"}}>
                                <LgNum>1.</LgNum>
                                They must be old growth material and must have the character and charm that represents the true historic architecture of an old or ancient growth tree.
                            </p>
                        </CopyRow>
                    </Copy>
                </ImageTextCopy>
                <Border>
                    <span />
                </Border>
                <Image>
                    <StaticImage src="../../images/about-1.jpg" alt="" />
                </Image>
            </ImageTextWrapper>
            <ImageTextWrapper>
                <ImageTextCopy>
                    <Copy>
                        <CopyRow>
                            <p style={{paddingLeft: "40px"}}>
                                <LgNum>2.</LgNum>
                                We hand select trees from only the best orchards that afforded the trees the widest growth areas. 
                            </p>
                            <p style={{paddingLeft: "40px"}}>
                                Many orchards cram their trees into as little as 10’x10’ spacing. Our olive trees have been well spaced by a height + 30% of height ration between trees which promotes a horizontal growth and character reminiscent of the Italian countryside.
                            </p>
                        </CopyRow>
                    </Copy>
                </ImageTextCopy>
                <Border>
                    <span />
                </Border>
                <Image>
                    <StaticImage src="../../images/about-2.jpg" alt="" />
                </Image>
            </ImageTextWrapper>
            <ImageTextWrapper>
                <ImageTextCopy>
                    <Copy>
                        <CopyRow>
                            <p style={{paddingLeft: "40px"}}>
                                <LgNum>3.</LgNum>
                                They must be strong, vigorous, and appear to be free of disease or character flaws that would weaken the tree or shorten its live span. These trees will provide character to your project for decades to come. 
                            </p>
                        </CopyRow>
                    </Copy>
                </ImageTextCopy>
                <Border>
                    <span />
                </Border>
                <Image>
                    <StaticImage src="../../images/about-3.jpg" alt="" />
                </Image>
            </ImageTextWrapper>
        </ImageTextElm>
    )
}

export default ImageText
