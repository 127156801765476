import styled from 'styled-components'
import { theme } from '../../styles'

export const ImageTextElm = styled.section`
    margin: 40px 0;

    @media(min-width: 700px) {
        margin: 80px 0;
        border-bottom: 55px solid ${theme.white};
        border-top: 55px solid ${theme.white};
    }
`;

export const Image = styled.div`
    @media(min-width: 700px) {
        width: 45%;
    }

    .gatsby-image-wrapper {
        height: 100%;
        /* border: 1px solid #AAA; */
    }
`;

export const ImageTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 auto 48px;
    width: 96%;

    @media(min-width: 700px) {
        flex-direction: row;
        justify-content: space-between;
        width: 80%;

        &:nth-child(even) {
            flex-direction: row-reverse;
        }
    }

    @media(min-width: 1000px) {
        width: 90%;
    }

    @media(min-width: 1400px) {
        width: 80%;
    }

    &:first-child {
        ${Image} {
            @media(min-width: 700px) {
                margin-top: -50px;
            }
        }
    }

    &:last-child {
        ${Image} {
            margin-bottom: 12px;
            @media(min-width: 700px) {
                margin-bottom: -75px;
            }
        }
    }
`;

export const ImageTextCopy = styled.div`
    margin-bottom: 30px;
    @media(min-width: 700px) {
        display: flex;
        align-items: center;
        margin-bottom: 0;
        width: 45%;
    }

    strong {
        font-size: 1.1em;
        font-weight: 400;
        line-height: 1.5;
    }
`;

export const IntroText = styled.div`
    margin-bottom: 60px;
    padding-top: 70px;
`;

export const Copy = styled.div`
    p {
        position: relative;
        font-weight: 300;
        line-height: 1.5;
    }
`;

export const LgNum = styled.span`
    position: absolute;
    left: 10px;
    font-family: ivypresto-display, serif;
    font-size: 30px;
`;

export const CopyRow = styled.div`
    position: relative;

    &:before {
        background: red;
        height: 1px;
        width: 100%;
        content: "";
    }
`;

export const Border = styled.div`
    display: none;

        @media(min-width: 700px) {
            display: flex;
            align-items: center;
            height: 430px;
            padding: 0 16px;
            width: 10%;
        }

        span {
            display: block;
            height: 1px;
            width: 100%;
            background: ${theme.grey};
        }
`;

