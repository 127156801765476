import React from "react"
import { Link } from 'gatsby'
import Layout from "../components/Layout"
import TextBlock from "../components/TextBlock"
import ContactCallout from '../components/ContactCallout'
import SectionHeading from "../components/SectionHeading"
import FeaturedSlider from "../components/FeaturedSlider"
import ImageGrid from "../components/ImageGrid"
import { ImageGridWrapper } from '../components/ImageGrid/ImageGridStyles'
import GridImageOne from "../images/olive-tall-one.jpg"
import GridImageTwo from "../images/olive-tall-two.jpg"
import GridImageThree from "../images/olive-wide-one.jpg"
import ImageText from "../components/ImageTextBlock"
import HeroVideo from "../components/HeroVideo"
import { Helmet } from 'react-helmet'

const IndexPage = () => {
  const textBlockCopy = [
    {
      para: "Ancient Olives is a family-run operation specializing in supplying hand-selected ancient and old-growth olive trees throughout Arizona and Southern California."
    }
  ]

  const gridImages = [
    {
      imgSrc: `${GridImageOne}`,
      altText: "Alt text 1"
    },
    {
      imgSrc: `${GridImageTwo}`,
      altText: "Alt text 2"
    },
    {
      imgSrc: `${GridImageThree}`,
      altText: "Alt text 3"
    }
  ]

  const ImageTextBlocks = [
    {
      para: "<p><strong>We offer hand-selected tree stock that has been carefully chosen by experienced professionals. We have 3 principles we follow when selecting our olives trees:</strong><p>"
    }
  ]

  return (
    <Layout>
        <Helmet htmlAttributes={{lang: 'en'}}>
          <title>Ancient Olives | Ancient and Old-Growth Olive Trees Direct to You</title>
          <meta name="description"
            content="Ancient Olives is a family-run operation specializing in supplying 
            hand-selected ancient and old-growth olive trees throughout Arizona and 
            Southern California."
          />
        </Helmet>
        <HeroVideo
          heading={`Ancient and Old-Growth Olive Trees Direct to You`}
        />
        <section className="top-padding bottom-padding">
          <SectionHeading>
            Enhance your project with hand-selected Ancient Olive Trees 
          </SectionHeading>
          <TextBlock
            text={textBlockCopy.map(text => <p key={text.para}>{text.para}</p>)}
            alignmentClass={`centered`}
            styleClass={`lg`}
          />
        </section>
        
        <FeaturedSlider />

        {ImageTextBlocks.map(block => (
          <ImageText
            backgroundColor="#F4F2EF"
            copy={block.para}
            key={block.para}
          />
        ))}

        <section
          className="top-padding bottom-padding centered"
          style={{
            maxWidth: "830px",
            margin: "0 auto"
          }}
        >
          <SectionHeading>
            No matter how difficult it is to get the trees to you, we are up to the challenge.
          </SectionHeading>
          <Link
            to={`/contact/`}
            style={{
              display: "inline-block",
              borderBottom: "1px solid #333",
              textTransform: "uppercase"
            }}
          >Send us a message</Link>
        </section>

        <ImageGridWrapper>
          {gridImages.map(image => (
            <ImageGrid
              imagePath={`${image.imgSrc}`}
              key={image.altText}
            />
          ))}
        </ImageGridWrapper>
        <ContactCallout heading={`Need help with choosing the right tree?`} />
    </Layout>
  )
}

export default IndexPage
