import React from 'react'
import Slider from "react-slick"
import {
    FeaturedSliderWrapper,
    Slide
} from './FeaturedSliderStyles'
import PsLifeLogo from "../../images/pslife-logo.svg" 
import PsModCom from "../../images/psmodco-logo.svg"
import Aia from "../../images/aia-logo.svg"
import LaTimes from "../../images/la-times.svg"
import Wallpaper from "../../images/wallpaper.svg"

const FeaturedSlider = () => {
    const logos = [
        {
            image: `${PsLifeLogo}`,
            altText: "Palm Springs Life Logo"
        },
        {
            image: `${PsModCom}`,
            altText: "PS mod com Logo"
        },
        {
            image: `${Aia}`,
            altText: "AIA | LA logo"
        },
        {
            image: `${LaTimes}`,
            altText: "Los Angeles Logo"
        },
        {
            image: `${Wallpaper}`,
            altText: "Wallpaper Logo"
        },
    ]

    const settings = {
        arrows: false,
        focusOnSelect: true,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                  slidesToShow: 5
                }
            },
            {
                breakpoint: 900,
                settings: {
                  slidesToShow: 4
                }
            },
            {
              breakpoint: 700,
              settings: {
                slidesToShow: 3
              }
            },
            {
                breakpoint: 500,
                settings: {
                  slidesToShow: 1
                }
            }
        ]
    };

    return (
        <section>
            <FeaturedSliderWrapper>
                <h3 className="centered" style={{
                    marginBottom: "24px",
                    marginTop: "0",
                    fontSize: "20px",
                    textTransform: "uppercase",
                    fontFamily: "Futura PT",
                    fontWeight: "400"
                }}>
                    Our trees have been featured in:
                </h3>
                <Slider {...settings}>
                    {logos.map(logo => (
                        <Slide key={logo.altText}>
                            <img src={logo.image} alt={logo.altText} />
                        </Slide>
                    ))}
                </Slider>
            </FeaturedSliderWrapper>
        </section>
    )
}

export default FeaturedSlider
