import React from 'react'
import {
    HeroVideoElm
} from '../HeroVideo/HeroVideoStyles'
import { PrimaryButton } from '../../styles'
import LoopingVideo from '../../videos/hp-banner-video.mp4'

const HeroVideo = ({heading}) => {
    return (
        <HeroVideoElm>
            <video className="videoBG"
                autoPlay={true}
                muted={true}
                playsInline={true}
                loop={true}
            >
                <source src={LoopingVideo} type="video/mp4" />
            </video>
            <h1 className="centered">{heading}</h1>
            <PrimaryButton to={`/contact/`} modifiers={['tan']}>
                Request A Quote
            </PrimaryButton>
        </HeroVideoElm>
    )
}

export default HeroVideo
