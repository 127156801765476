import styled from 'styled-components'
import { PrimaryButton, theme } from '../../styles'

export const HeroVideoElm = styled.section`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 90vh;
    padding: 0 12px;
    overflow: hidden;
    z-index: 0;

    @media(min-width: 870px) {
        margin: 32px 32px 0;
        min-height: 620px;
        padding: 0 40px;
    }

    @media(min-width: 1400px) {
        height: calc(100vh - 64px);
    }

    &:before {
        position: absolute;
        left: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        background: rgba(0, 0, 0, 0.25);
        content: "";
        z-index: 1;
    }

    h1 {
        position: relative;
        margin-bottom: 32px;
        max-width: 780px;
        color: ${theme.white};
        font-size: 2.5em;
        line-height: 1.4;
        text-shadow: 1px 1px 1px ${theme.black};
        z-index: 2;
        @media(min-width: 870px) {
            font-size: 3.333em;
        }
    }

    ${PrimaryButton} {
        position: relative;
        z-index: 2;
    }

    .videoBG {
        position: absolute;
        top: 50%;
        left: 50%;
        min-width: 100%; 
        min-height: 100%; 
        width: auto;
        height: auto;
        transform: translate(-50%,-50%); 
    }
`;